<template>
  <div
    v-if="transformedSupportedLanguages.length"
    class="app-language-switcher"
    :class="{ disabled, loading }"
  >
    <v-select
      v-model="language"
      item-value="value"
      :items="transformedSupportedLanguages"
      :label="$t('language')"
      hide-details
      attach
      solo
      ref="language-switcher"
      @mouseup="onClick"
      :disabled="disabled || transformedSupportedLanguages.length === 1"
    >
      <template slot="selection" slot-scope="data">
        <span :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"></span>
        <span>{{ data.item.name }}</span>
      </template>
      <template slot="item" slot-scope="data">
        <span :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"></span>
        <span class="slot-item">{{ data.item.name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Boolean,
      default: false,
    },
    allLanguages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    appLanguages() {
      return process.env.VUE_APP_GROUP_LANGUAGES
        ? process.env.VUE_APP_GROUP_LANGUAGES.split(",")
        : [];
    },
    defaultLanguage() {
      if (
        !this.activeGroup ||
        !this.activeGroup.attributes ||
        !this.activeGroup.attributes.language_iso
      ) {
        return null;
      }
      return this.activeGroup.attributes.language_iso;
    },
    supportedLanguages() {
      if (this.allLanguages) return this.appLanguages;
      if (
        !this.activeGroup ||
        !this.activeGroup.attributes ||
        !this.activeGroup.attributes.supported_languages
      ) {
        return [];
      }
      return this.activeGroup.attributes.supported_languages.split(",");
    },
    validDefaultLanguage() {
      return this.supportedLanguages.includes(this.appContentLanguage)
        ? this.appContentLanguage
        : null;
    },
    language: {
      get() {
        if (this.allLanguages) {
          return this.appContentLanguage || this.appLanguages[0];
        }
        return this.validDefaultLanguage || this.defaultLanguage;
      },
      set(val) {
        this.setAppContentLanguage(val);
      },
    },
    transformedSupportedLanguages() {
      return this.supportedLanguages.map((el) => ({
        name: this.$t(`common.${el}_label`),
        value: el,
        iso2: this.getIso2(el),
      }));
    },
  },
  mounted() {
    this.$nextTick(() => this.setAppContentLanguage(this.language));
  },
  methods: {
    onClick() {
      if (!this.events) return;

      this.$emit("click");

      setTimeout(() => {
        this.$refs["language-switcher"].isMenuActive = false;
      }, 0);
    },
    open() {
      setTimeout(() => {
        this.$refs["language-switcher"].isMenuActive = true;
      }, 0);
    },
    getIso2(key) {
      const lang = languageIcons.find((el) => el.key === key);

      return lang ? lang.iso2 : key;
    },
    setAppContentLanguage(val) {
      if (!this.transformedSupportedLanguages.length > 0) {
        return;
      }

      this.$store.commit(
        "SET_APP_CONTENT_LANGUAGE",
        val || this.transformedSupportedLanguages[0].value,
      );
    },
  },
};
</script>

<style lang="scss">
.app-language-switcher {
  width: 200px;

  &.disabled {
    .flag-icon {
      opacity: 0.3;
    }
  }

  &.loading {
    .v-menu__content {
      .v-select-list {
        .v-list {
          .v-list__tile {
            opacity: 0.3 !important;
            user-select: none !important;
            pointer-events: none !important;
          }
        }
      }
    }
  }

  .v-input__slot {
    padding: 0 16px 0 22px !important;
    border-radius: 19px !important;
  }

  .v-menu__content {
    border-radius: 7px !important;
  }

  .v-input__control {
    min-height: 38px !important;
  }

  .selection-item {
    font-size: 14px;
  }
}
</style>
