<template>
  <v-container class="general">
    <page-title :component="'ArticleListPage'" :group-plugin-id="groupPluginId">
      <template v-slot:actions>
        <v-text-field
          v-model="search"
          flat
          light
          solo
          hide-details
          class="search-input body-1"
        >
          <template slot="append">
            <font-awesome-icon
              :icon="['fas', 'search']"
              style="font-size: 18px"
              class="ml-1 sw-accent"
            ></font-awesome-icon>
          </template>
        </v-text-field>
        <LanguageSwitcher class="ml-3" />
        <VideoTutorial
          :component="'ArticleListPage'"
          :title="$t('articles-tutorial-title')"
          class="ml-3"
        />
      </template>
    </page-title>
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !groupArticles.length && !isSearching"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          <div v-if="!search">
            {{ $t("article_list_page.nothing_created") }}
          </div>
          <div v-if="search">{{ $t("article_list_page.nothing_found") }}</div>
        </v-flex>
      </v-layout>
    </transition>
    <template v-if="groupArticles && groupArticles.length">
      <div
        v-for="(article, i) in groupArticles"
        :key="`article-${i}`"
        class="px-4"
      >
        <v-layout :style="{ width: '100%' }" class="row py-3 align-center">
          <v-flex class="xs12">
            <a
              class="sw-h5 font-weight-light"
              @click.stop="editGroupArticle(article.id)"
            >
              {{ article.name }}
            </a>
          </v-flex>
          <v-btn icon class="my-0 mx-0" @click="editGroupArticle(article.id)">
            <font-awesome-icon class="sw-accent" icon="pen" />
          </v-btn>
          <v-btn
            icon
            class="my-0 mx-0"
            :loading="isVuexLoading(`deleteGroupArticle-${article.id}`)"
            @click="deleteGroupArticle(article.id)"
          >
            <font-awesome-icon class="sw-accent" icon="trash" />
          </v-btn>
        </v-layout>
        <v-divider></v-divider>
      </div>
    </template>
    <div v-if="totalPages > 1" class="text-center pt-4">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listGroupArticles()"
      ></v-pagination>
    </div>
    <AppSpeedDial
      @click="$router.push({ name: 'articles_create' })"
      :hint="$t('article_list_page.fab_description')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    isSearching: false,
    groupArticles: [],
    search: "",
    timeout: null,
    page: null,
    perPage: 15,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  components: {
    LanguageSwitcher,
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listGroupArticles();
      },
    },
    groupPluginId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal && String(newVal) === String(oldVal)) {
          return;
        }

        this.page = this.currentPage;
        this.listGroupArticles();
      },
    },
    search() {
      this.isSearching = true;

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.isSearching = false;
        this.page = 1;
        this.listGroupArticles();
      }, 500);
    },
  },
  methods: {
    async listGroupArticles() {
      try {
        this.isLoading = true;

        const params = [
          this.groupId,
          {
            group_plugin_id: this.groupPluginId,
            search: this.search || "",
            sort: "created_at:desc",
            with_attributes: 1,
            lang: this.appContentLanguage,
            page: this.page,
            per_page: this.perPage,
          },
        ];

        const response = await this.$api.groupArticles.list(...params);

        if (!response) return;

        this.groupArticles = response.data.data;
        this.totalPages = response.data.pagination.total_pages;

        this.query(this.page);

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    editGroupArticle(id) {
      this.$router.push(
        {
          name: "articles_edit",
          params: {
            article_id: id,
            group_plugin_id: this.groupPluginId,
          },
        },
        () => {},
      );
    },
    async deleteGroupArticle(articleId) {
      if (!articleId) return;

      try {
        const articleToDelete = this.groupArticles.find(
          (el) => el.id === articleId,
        );

        if (!articleToDelete) return;

        const isConfirmed = await this.$refs.confirmationDialog.open(
          this.$t("article_list_page.delete_warning", {
            name: `${articleToDelete.name}`,
          }),
          this.$t("common.no"),
          this.$t("common.yes"),
        );

        if (!isConfirmed) return;

        this.startVuexLoading(`deleteGroupArticle-${articleId}`);

        const params = [this.groupId, articleId];

        await this.$api.groupArticles.delete(...params);

        this.$store.dispatch("addNotification", {
          message: this.$t("article_list_page.delete_success", {
            name: articleToDelete.name,
          }),
        });

        this.page = 1;
        await this.listGroupArticles();

        this.endVuexLoading(`deleteGroupArticle-${articleId}`);
      } catch (error) {
        this.endVuexLoading(`deleteGroupArticle-${articleId}`);
        this.errorMessageShow(error);
      }
    },
    query(page) {
      this.$router.replace(
        {
          query: Object.assign({}, this.$route.query, { page: page }),
        },
        () => {},
      );
    },
  },
};
</script>

<style lang="scss"></style>
